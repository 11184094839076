import React from "react";
import DashboardCard from "./DashboardCard";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee } from '@fortawesome/free-solid-svg-icons'
import './social.css';

const DashboardContent = ({ quizzes, name }) => {
  function getLink(routeName) {
    if (quizzes.length) {
      const quiz = quizzes.filter((quiz) => quiz.name === routeName)[0];
      console.log("quiz", quiz);
      if (quiz) return `/${routeName}/${quiz._id}`;
    }
    return "/dashboard";
  }
  return (
    <>
      <div
        className="container row"
        data-aos="fade-right"
        data-aos-duration="3000"
      >
        <div className="col-md-12 col-sm-12 col-12">
          <div className="dashboard">
            <h1>
              <span className="dash">DASH</span>
              <span className="board">BOARD</span>
            </h1>
          </div>
          <div className="container">
            <div className="jumbotron">
              <h3>You have successfully registered for the Owasp Recruitment Quiz 🎊</h3>
              {/* <p>• If a participant is found indulging in any malpractices, he/she will be disqualified from the recruitment process.</p> */}
              <h2>All the Best ✨</h2>
            </div>
            <div className="jumbotron">
              <h2>Follow us at:</h2>
              {/* <p>• If a participant is found indulging in any malpractices, he/she will be disqualified from the recruitment process.</p> */}
              <div>
              <div className="social-handles">
                <a className="social-icons" id="ig" href="https://www.instagram.com/owasp_tiet/">Instagram</a>
                <a className="social-icons" id="dc" href="https://discord.gg/MXCDkWrRe3">Discord</a>
                <a className="social-icons" id="linkedin" href="https://www.linkedin.com/company/owasp-tiet/mycompany/">LinkedIn</a>
                <a className="social-icons" id="github" href="https://github.com/OWASP-STUDENT-CHAPTER">Github</a>
                <a className="social-icons" id="web" href="https://owasp.co.in/">Web</a>
                
              </div>
              </div>
            </div>
            <div className="row">
              <div className="flex-container">
                {/* <div className="flex-item">
                  <div className="flex-item-inner">
                    <div className="card-front">
                      <i
                        className={`${"fa fa-video-camera"} fa-3x tile-icon icon-white`}
                      ></i>

                      <h3>Recruitment link</h3>
                    </div>
                    <a href={"http://bit.ly/OWASPorientation"}>
                    <div className="card-back bg-dark">
                      <p className="title">{title}</p>
                      <p className="desc">Will be live soon.</p>
                      <p className="link">
                        <i className="fa fa-chevron-circle-right"></i>
                      </p>
                    </div>
                    </a>
                  </div> */}
                {/* </div> */}
                {/* <DashboardCard
                  iconClass="fa fa-video-camera"
                  heading="Orientation link"
                  title="Orientation on Youtube"
                  desc="Youtube live will be streamed at 5:30pm. Please be on time."
                  link="http://bit.ly/OWASPorientation"
                /> */}
                {/* <DashboardCard
                  iconClass="fa fa-indent"
                  heading="Recruitment quiz for First Year - Slot 1"
                  title="Only for first year students"
                  desc="Slot 1 : Quiz will be live at 3:10pm to 3:30pm"
                  link={getLink("Recruitment_FirstYear_1")}
                />
                <DashboardCard
                  iconClass="fa fa-indent"
                  heading="Recruitment quiz for First Year - Slot 2"
                  title="Only for first year students"
                  desc="Slot 2 : Quiz will be live at 3:55pm to 4:15pm"
                  link={getLink("Recruitment_FirstYear_2")}
                />
                <DashboardCard
                  iconClass="fa fa-indent"
                  heading="Recruitment quiz for First Year - Slot 3"
                  title="Only for first year students"
                  desc="Slot 3 : Quiz will be live at 4:40pm to 5:00pm"
                  link={getLink("Recruitment_FirstYear_3")}
                />
                <DashboardCard
                  iconClass="fa fa-indent"
                  heading="Recruitment quiz for First Year - Slot 4"
                  title="Only for first year students"
                  desc="Slot 4 : Quiz will be live at 5:25pm to 5:45pm"
                  link={getLink("Recruitment_FirstYear_4")}
                />
                <DashboardCard
                  iconClass="fa fa-indent"
                  heading="Recruitment quiz for First Year - Slot 5"
                  title="Only for first year students"
                  desc="Slot 5 : Quiz will be live at 6:10pm to 6:30pm"
                  link={getLink("Recruitment_FirstYear_5")}
                />
                <DashboardCard
                  iconClass="fa fa-indent"
                  heading="Recruitment quiz for First Year - Slot 6"
                  title="Only for first year students"
                  desc="Slot 6 : Quiz will be live at 6:55pm to 7:15pm"
                  link={getLink("Recruitment_FirstYear_6")}
                /> */}
                {/* <DashboardCard
                  iconClass="fa fa-file-text"
                  heading="Recruitment form for 2nd year"
                  title="Only for Second year students"
                  desc="Form link will be live at 5:30pm"
                  link={getLink("codingquiz")}
                /> */}


                  <DashboardCard
                  iconClass="fa fa-indent"
                  heading="Recruitment quiz for First Year - Slot 1"
                  title="Only for first year students"
                  desc="Slot 1 : Quiz will be live at 6:10pm to 6:30pm"
                  link={getLink("2027_Recruitment_1")}
                />
                <DashboardCard
                  iconClass="fa fa-indent"
                  heading="Recruitment quiz for First Year - Slot 2"
                  title="Only for first year students"
                  desc="Slot 2 : Quiz will be live at 6:50pm to 7:10pm"
                  link={getLink("2027_Recruitment_2")}
                />
                <DashboardCard
                  iconClass="fa fa-indent"
                  heading="Recruitment quiz for First Year - Slot 3"
                  title="Only for first year students"
                  desc="Slot 3 : Quiz will be live at 7:30pm to 7:50pm"
                  link={getLink("2027_Recruitment_3")}
                /> 
                {/* <DashboardCard
                  iconClass="fa fa-indent"
                  heading="Recruitment quiz for First Year - Slot 4"
                  title="Only for first year students"
                  desc="Slot 4 : Quiz will be live at 8:00pm to 8:20pm"
                  link={"2027_Recruitment_4"}
                />  */}

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardContent;
// import React from "react";
// import DashboardCard from "./DashboardCard";

// const DashboardContent = ({ quizzes, name }) => {
//   function getLink(routeName) {
//     if (quizzes.length) {
//       const quiz = quizzes.filter((quiz) => quiz.name === routeName)[0];
//       console.log("quiz", quiz);
//       if (quiz) return `/${routeName}/${quiz._id}`;
//     }
//     return "/dashboard";
//   }
//   return (
//     <>
//       <div
//         className="container row"
//         data-aos="fade-right"
//         data-aos-duration="3000"
//       >
//         <div className="col-md-12 col-sm-12 col-12">
//           <div className="dashboard">
//             <h1>
//               <span className="dash">DASH</span>
//               <span className="board">BOARD</span>
//             </h1>
//           </div>
//           <div className="container">
//             <div className="jumbotron">
//               <p>
//                 Hey {name},Thank you for registering! We welcome you to the
//                 Owasp student chapter portal! <br></br>
//                 <strong>
//                   In order to be irreplaceable, one must always be different.
//                 </strong>
//                 <br></br>
//                 The world today requires multi-tasking and multi-skilled
//                 engineers who can write the most efficient code at one time and
//                 pitch the business ideas on the other. OWASP Student Chapter
//                 TIET, an elite coding club, that prepares you for the Tech world
//                 and simultaneously equips you with Non-Tech skills.
//                 <br></br>
//                 Quiz will be held in two slots i.e at <strong>
//                   5:00pm
//                 </strong>{" "}
//                 and <strong>6:00pm</strong> on{" "}
//                 <strong>11th September 2021</strong>. Each slot will be of <strong>20 minutes</strong> .You can attempt any{" "}
//                 <strong>one</strong> slot.
//                 <br></br>
//                 {/* For 2nd year candidates : On 1st October, Recruitment form will
//                 be live at <strong>5:30pm</strong>. */}
//                 <br></br>
//                 <a class="owasp-site-link" href="http://owasp.co.in/">
//                   Our Offical Website
//                 </a>
//               </p>
//             </div>
//             <div className="row">
//               <div className="flex-container">
//                 {/* <div className="flex-item">
//                   <div className="flex-item-inner">
//                     <div className="card-front">
//                       <i
//                         className={`${"fa fa-video-camera"} fa-3x tile-icon icon-white`}
//                       ></i>

//                       <h3>Recruitment link</h3>
//                     </div>
//                     <a href={"http://bit.ly/OWASPorientation"}>
//                     <div className="card-back bg-dark">
//                       <p className="title">{title}</p>
//                       <p className="desc">Will be live soon.</p>
//                       <p className="link">
//                         <i className="fa fa-chevron-circle-right"></i>
//                       </p>
//                     </div>
//                     </a>
//                   </div> */}
//                 {/* </div> */}
//                 {/* <DashboardCard
//                   iconClass="fa fa-video-camera"
//                   heading="Orientation link"
//                   title="Orientation on Youtube"
//                   desc="Youtube live will be streamed at 5:30pm. Please be on time."
//                   link="http://bit.ly/OWASPorientation"
//                 /> */}
//                 <DashboardCard
//                   iconClass="fa fa-indent"
//                   heading="Recruitment quiz for Second Year - Slot 1"
//                   title="Only for second year students"
//                   desc="Slot 1 : Quiz will be live at 5:00pm to 5:20pm"
//                   link={getLink("Recruitment_SecondYear_1")}
//                 />
//                 <DashboardCard
//                   iconClass="fa fa-indent"
//                   heading="Recruitment quiz for Second Year - Slot 2"
//                   title="Only for second year students"
//                   desc="Slot 2 : Quiz will be live at 6:00pm to 6:20pm"
//                   link={getLink("Recruitment_SecondYear_2")}
//                 />
//                 {/* <DashboardCard
//                   iconClass="fa fa-file-text"
//                   heading="Recruitment form for 2nd year"
//                   title="Only for Second year students"
//                   desc="Form link will be live at 5:30pm"
//                   link={getLink("codingquiz")}
//                 /> */}
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default DashboardContent;

import React from "react";
import { Helmet } from "react-helmet";

import NavbarComponent from "../Components/Navbar";
import OrientationPage from "../Components/orientationPage";

const CompleteRegistration = () => {
  return (
    <>
      <Helmet>
        <link rel="stylesheet" href="/css/register.css" />
      </Helmet>
      <NavbarComponent />
      <OrientationPage />
    </>
  );
};

export default CompleteRegistration;
